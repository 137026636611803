import gql from 'graphql-tag'
import entityFragment from './EntityFragment'

export const ENTITIES_QUERY = gql`
  query Entities {
    entities: entities {
      ...${entityFragment}
    }
  }
`

export const GET_ENTITY_TRANSACTIONS_QUERY = gql`
  query GetEntityTransactions($entityId: ID!) {
    transactions: getEntityTransactions(entityId: $entityId) {
      id
      amountPaid
      code
      date
      module {
        name
      }
      plan {
        id
        duration
      }
      status
      totalUsers
      totalProfiles
      value
    }
  }
`